import React, {useEffect, useState, useRef} from "react";

const Player = (props) => {
    let [internalPlayerScript, setInternalPlayerScript] = useState(props.playerScript)
    let dacastPlayer = useRef();
    let [dacastPlayerReady, setDacastPlayerReady] = useState(false);

    useEffect(() => {
        initPlayer(props.playerScript, 'player-wrapper');
        return () => {
            dacastPlayer.current.dispose()
        }
    }, []);

    useEffect(() => {
        if (dacastPlayerReady) {
            setVideoTime()
        }
    }, [dacastPlayerReady]);

    const setVideoTime = () => {
        let isCoffeeBreak = props.event.is_coffee_break;
        let isReplayMode = props.event.status == 'replay';
        let coffeeBreakTimestamp = props.event.coffee_break_timestamp;

        if (isCoffeeBreak && !isReplayMode) {
            let now = new Date().getTime() / 1000;
            let timePassedSinceBeginning = now - coffeeBreakTimestamp;
            let videoDuration = dacastPlayer.current.getContentInfo().duration
            dacastPlayer.current.currentTime(timePassedSinceBeginning);

            if (videoDuration < timePassedSinceBeginning - 5) {
                dacastPlayer.current.off("pause", () => {
                    dacastPlayer.current.play();
                });
                dacastPlayer.current.pause()
            }
        }
    }

    const updateVolume = (videoEl) => {
        let isMuted = dacastPlayer.current.getPlayerInstance().muted || dacastPlayer.current.isMuted() || videoEl.muted;

        console.log({
            "dacastPlayer.current.volume()": dacastPlayer.current.volume(),
            "dacastPlayer.current.getPlayerInstance().volume": dacastPlayer.current.getPlayerInstance().volume,
            "dacastPlayer.current.getPlayerInstance().muted": dacastPlayer.current.getPlayerInstance().muted,
            "dacastPlayer.current.isMuted()": dacastPlayer.current.isMuted(),
            "props.volume": props.volume,
            "videoEl.muted": videoEl.muted,
            "videoEl.volume": videoEl.volume,
        })

        if (isMuted) {
            props.setVolume(0)
            dacastPlayer.current.volume(0)
        } else {
            if (dacastPlayer.current.volume()) {
                props.setVolume(dacastPlayer.current.volume())
            } else {
                props.setVolume(dacastPlayer.current.getPlayerInstance().volume)
            }
        }
    }

    const initPlayer = (feed, div) => {
        dacastPlayer.current = dacast(feed, div, {
            //player: 'vjs5',
            player: 'html5hls',
            persistVolume: false, // Do not save the volume to the session
            mutedAutoplay: true, // Always play on mute,
        });


        dacastPlayer.current.on("ready", () => {
            const videoEl = dacastPlayer.current.getElement().querySelector('video')

            /**
             * /!\
             * We don't want the user to be able to pause the feed, apart from replay mode
             */
            let isReplayMode = props.event.status == 'replay';
            if (!isReplayMode) {
                dacastPlayer.current.on("pause", () => {
                    videoEl.play().then(() => {
                        props.setIsPlaying(true)
                    }).catch(error => {
                        console.log(error)
                        props.setIsPlaying(false)
                    });
                });
            }

            dacastPlayer.current.on("play", () => {
                props.setIsPlaying(true)

                updateVolume(videoEl)
            })
            // Allow pausing only in replay mode
            if (!isReplayMode) {
                dacastPlayer.current.on("pause", () => {
                    props.setIsPlaying(false)
                    dacastPlayer.current.play()
                })
            }

            dacastPlayer.current.on("error", (err) => {
                console.log("error type:", err.dc_type)
                console.log("error msg:", err.dc_message)
            });

            updateVolume(videoEl)
            setDacastPlayerReady(true)
        });

        dacastPlayer.current = dacastPlayer.current



        // let dacastPlayer.current = dacast(feed, div, {
        //     //player: 'vjs5',
        //     player: 'flow7',
        //     persistVolume: false, // Do not save the volume to the session
        //     // mutedAutoplay: true, // Always play on mute
        // });
        //
        // dacastPlayer.current.onReady(function () {
        //     const videoEl = dacastPlayer.current.getElement().querySelector('video')
        //     console.log("Dacast player ready")
        //
        //     dacastPlayer.current.volume(props.volume)
        //     dacastPlayer.current.mute(false)
        //
        //     videoEl.play().then(() => {
        //         console.log("Success")
        //         if (videoEl.muted || videoEl.volume === 0) {
        //             props.setVolume(0)
        //             dacastPlayer.current.volume(0)
        //             props.setIsPlaying(true)
        //         }
        //     }).catch(error => {
        //         console.log("Failed to play - mute everything")
        //         props.setVolume(0)
        //         dacastPlayer.current.volume(0)
        //         videoEl.muted = true;
        //         videoEl.play().then(() => {
        //             props.setIsPlaying(true)
        //         }).catch(error => {
        //             props.setIsPlaying(false)
        //         });
        //     });
        //
        //     let isMuted = dacastPlayer.current.getPlayerInstance().muted || dacastPlayer.current.isMuted() || videoEl.muted;
        //
        //     console.log({
        //         "dacastPlayer.current.volume()": dacastPlayer.current.volume(),
        //         "dacastPlayer.current.getPlayerInstance().volume": dacastPlayer.current.getPlayerInstance().volume,
        //         "dacastPlayer.current.getPlayerInstance().muted": dacastPlayer.current.getPlayerInstance().muted,
        //         "dacastPlayer.current.isMuted()": dacastPlayer.current.isMuted(),
        //         "props.volume": props.volume,
        //         "videoEl.muted": videoEl.muted,
        //         "videoEl.volume": videoEl.volume,
        //     })
        //
        //     console.log("Is muted", isMuted)
        //
        //     if (isMuted) {
        //         props.setVolume(0)
        //         dacastPlayer.current.volume(0)
        //     } else {
        //         if (dacastPlayer.current.volume()) {
        //             props.setVolume(dacastPlayer.current.volume())
        //         } else {
        //             console.log("Falback")
        //             props.setVolume(dacastPlayer.current.getPlayerInstance().volume)
        //         }
        //     }
        //
        //     /*
        //
        //     if (dacastPlayer.current.volume()) {
        //         props.setVolume(dacastPlayer.current.volume())
        //     } else {
        //         console.log("Falback")
        //         props.setVolume(dacastPlayer.current.getPlayerInstance().volume)
        //     }
        //
        //     if (dacastPlayer.current.getPlayerInstance().muted !== false) {
        //         props.setVolume(0)
        //     }
        //
        //     if (dacastPlayer.current.isMuted()) {
        //         props.setVolume(0)
        //     }*/
        //
        //     /**
        //      * /!\
        //      * We don't want the user to be able to pause the feed
        //      */
        //     dacastPlayer.current.on("pause", () => {
        //         console.log("On Pause")
        //
        //         /*
        //         videoEl.play().then(() => {
        //             props.setIsPlaying(true)
        //         }).catch(error => {
        //             props.setIsPlaying(false)
        //         });*/
        //     });
        //
        //     dacastPlayer.current.on("play", () => {
        //         props.setIsPlaying(true)
        //     })
        //
        //     dacastPlayer.current.onError(function (err) {
        //         console.log("error type:", err.dc_type)
        //         console.log("error msg:", err.dc_message)
        //     });
        //
        //     setDacastPlayerReady(true)
        // });
        //
        // setDacastPlayer(dacastPlayer.current)
    }

    /*
    useEffect(() => {
        if (dacastPlayer.current) {
            if (props.isPlaying) {
                const videoEl = dacastPlayer.current.getElement().querySelector('video')
                videoEl.play().then(() => {
                    props.setIsPlaying(true)
                }).catch(error => {
                    console.log(error)
                    props.setIsPlaying(false)
                });
            } else {
                dacastPlayer.current.pause()
            }
        }
    }, [props.isPlaying])
     */

    useEffect(() => {
        if (dacastPlayer.current) {
            dacastPlayer.current.volume(props.volume)

            if (props.volume > 0) {
                dacastPlayer.current.mute(false)
            } else {
                dacastPlayer.current.mute(true)
            }
        }
    }, [props.volume])

    useEffect(() => {
        if (props.playerScript !== internalPlayerScript) {
            console.log("Updated player script")
            setInternalPlayerScript(props.playerScript)
        }
    }, [props.playerScript])

    useEffect(() => {
        if (dacastPlayer.current && props.playerScript !== internalPlayerScript) {
            dacastPlayer.current.dispose();
            dacastPlayer.current = null
            setDacastPlayerReady(false)
            initPlayer(props.playerScript, 'player-wrapper');
        }
    }, [props.playerScript, internalPlayerScript])

    return (
        <div className="player-wrapper" id="player-wrapper"/>
    )
};
export default Player;
