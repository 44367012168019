import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import Live from "../components/live/Live";
import Header from "../components/common/Header";
import After from "../components/after/After";
import Before from "../components/before/Before";
import Footer from "../components/common/Footer";

import {
    isDesktop,
    isTablet,
    isMobileOnly,
    isIE
} from "react-device-detect";

const IeWarning = (props) => {
    return <p className={'text-center p-150'}>
        It looks like you are using Internet Explorer, which is not supported anymore.
        <br />
        Please download <a href="https://www.google.com/intl/fr/chrome/" style={{color: 'white', textDecoration: 'underline'}}>Chrome</a> or <a  style={{color: 'white', textDecoration: 'underline'}} href="https://www.mozilla.org/fr/firefox/new/">Firefox</a> to access this website.
    </p>
}

const ChatPlayer = (props) => {
    if (isIE) {
        return <IeWarning />
    }

    const [event, setEvent] = useState(props.currentEvent);
    const [selectedLanguage, setSelectedLanguage] = useState(props.languagePlayer)
    const [selectedFeed, setSelectedFeed] = useState(props.playerScript)
    const [isMobile, setIsMobile] = useState(false)
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    const resizeWindowCallback = () => setWindowSize(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', resizeWindowCallback)
        const params = new URL(window.location.href).searchParams;
        const language = params.get('language');


        /**
         * If we're on the event page, poll to see if we're connected
         */
        if (window.location.pathname.indexOf('/events/') >= 0 && !isIE && props.checkConnection) {
            const pollFrequency = 40000;

            function pollConnection() {
                window.axios.get("/user-connected")
                    .catch(function (error) {
                        if (error && error.response && error.response.status === 403) {
                            let redirectTo = new URL(window.location);
                            redirectTo.pathname = "/auth/disconnected";
                            window.location = redirectTo;
                        }
                    });
                setTimeout(pollConnection, pollFrequency);
            }
            setTimeout(pollConnection, pollFrequency);
        }

        if (params && language && event.stream_script[language]) {
            setSelectedLanguage(language)
            setSelectedFeed(event.stream_script[language])
        }

        return () => window.removeEventListener('resize', resizeWindowCallback)
    }, [])

    useEffect(() => {
        setIsMobile(isMobileOnly || window.innerWidth < 991)
    }, [windowSize])

    useEffect(() => {
        if (selectedLanguage && event.stream_script[selectedLanguage]) {
            const url = new URL(window.location.href)
            url.searchParams.set('language', selectedLanguage);
            window.history.pushState(null, null, url.toString())
            setSelectedFeed(event.stream_script[selectedLanguage])
        }
    }, [selectedLanguage, event])

    if (event.status !== "live" && event.replay_mode === 0 && event.is_coffee_break && event.parent_event !== undefined) {
        window.location = event.parent_event + "?language=" + selectedLanguage;

        // Fake "live"
        return (
            <>
                {!props.playerOnly && <Header
                    activeLocales={activeLocales}
                    event={event}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    displayName={displayName}
                    applicationStatus="live"
                />}

                <Live
                    userToken={props.userToken}
                    playerScript={selectedFeed}
                    playerOnly={props.playerOnly}
                    subscriptionStatus={props.subscriptionStatus}
                    socketIOServer={props.socketIOServer}
                    socketIOWebsocket={props.socketIOWebsocket}
                    roomName={props.roomName}
                    historyUrl={props.historyUrl}
                    displayName={props.displayName}
                    email={props.email}
                    username={props.username}
                    setEvent={setEvent}
                    event={{...event, status: 'live'}}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                />

                {!props.playerOnly && <Footer
                    displayName={displayName}
                    applicationStatus={event.status}
                />}
            </>
        )
    }

    return (
        <>
            {!props.playerOnly && <Header
                activeLocales={activeLocales}
                event={event}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                displayName={displayName}
                email={props.email}
                applicationStatus={event.status}
                isMobileOnly={isMobile}
            />}

            {event.status === 'not_started' && <Before
                startDate={event.start_date}
                isMobileOnly={isMobileOnly}
                selectedLanguage={selectedLanguage}
                event={event}
            />}

            <Live
                userToken={props.userToken}
                playerScript={selectedFeed}
                email={props.email}
                playerOnly={props.playerOnly}
                activeLocales={props.activeLocales}
                subscriptionStatus={props.subscriptionStatus}
                networkDiagnostics={props.networkDiagnostics}
                socketIOServer={props.socketIOServer}
                historyUrl={props.historyUrl}
                socketIOWebsocket={props.socketIOWebsocket}
                roomName={props.roomName}
                displayName={props.displayName}
                username={props.username}
                setEvent={setEvent}
                event={event}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                isMobileOnly={isMobile}
            />

            {event.status === 'finished' && <After event={event} selectedLanguage={selectedLanguage}/>}

            {!props.playerOnly && <Footer
                displayName={displayName}
                username={props.email}
                applicationStatus={event.status}
            />}
        </>
    )
}

if (document.getElementById('reactApp'))
{
    ReactDOM.render(<ChatPlayer
        userToken={userToken}
        playerScript={playerScript}
        checkConnection={checkConnection}
        playerOnly={playerOnly}
        languagePlayer={languagePlayer}
        activeLocales={activeLocales}
        networkDiagnostics={networkDiagnostics}
        socketIOServer={socketIOServer}
        socketIOWebsocket={socketIOWebsocket}
        subscriptionStatus={subscriptionStatus}
        historyUrl={event.enable_chat_history ? historyUrl:null}
        roomName={roomName}
        displayName={displayName}
        username={username}
        email={email}
        currentEvent={event}
    />, document.getElementById('reactApp'));
}
