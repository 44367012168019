import React from "react";
const Header = (props) => {
    return (
        <footer>

        </footer>
    )
}

export default Header
