export const translations = {
    en: {
        'visit_gphg': 'VISIT <a href="https://www.gphg.org/horlogerie/en" target="_blank">WWW.GPHG.ORG</a>',
        'days': 'Days',
        'hours': 'Hours',
        'mins': 'Mins',
        'secs': 'Secs',
        'fr': 'Français',
        'en': 'English',
        'make_sure_you_enable': 'Make sure you enable',
        'sound_by_clicking_here': 'the sound by clicking here!',
        'copyright': '© FONDATION DU GRAND PRIX D\'HORLOGERIE DE GENÈVE. ALL RIGHTS RESERVED.',
        'follow_us': 'Also follow the event on our social networks',
        'event_title': '2024 EDITION',
        'event_subtitle': 'PRESELECTED TIMEPIECES BY CATEGORIES',

        'category.ladies': 'Ladies\'',
        'category.ladies.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_FEMME',
        'category.ladies_complications': 'Ladies\' Complication',
        'category.ladies_complications.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_COMPLICATION_POUR_FEMME',
        'category.time_only': 'Time Only',
        'category.time_only.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_TIME_ONLY',
        'category.mens': 'Men\'s',
        'category.mens.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_HOMME',
        'category.mens_complications': 'Men\'s Complication',
        'category.mens_complications.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_COMPLICATION_POUR_HOMME',
        'category.iconic': 'Iconic',
        'category.iconic.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_ICONIQUE',
        'category.tourbillon': 'Tourbillon',
        'category.tourbillon.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_TOURBILLON',
        'category.calendar_astronomy': 'Calendar and Astronomy',
        'category.calendar_astronomy.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_CALENDRIER_ET_ASTRONOMIE',
        'category.mechanical_exception': 'Mechanical Exception',
        'category.mechanical_exception.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_EXCEPTION_MÉCANIQUE',
        'category.chronograph': 'Chronograph',
        'category.chronograph.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_CHRONOGRAPHE',
        'category.divers': 'Sports',
        'category.divers.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_SPORT',
        'category.jewellery': 'Jewellery',
        'category.jewellery.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_JOAILLERIE',
        'category.artistic_crafts': 'Artistic Crafts',
        'category.artistic_crafts.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_METIERS_D’ART',
        'category.petite_aiguille': '"Petite Aiguille"',
        'category.petite_aiguille.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_PETITE_AIGUILLE',
        'category.challenge': 'Challenge',
        'category.challenge.link': 'https://www.gphg.org/horlogerie/en/gphg-2024/nominated-timepieces#2024_PRE_CHALLENGE',

        'category.aiguille_dor': 'Aiguille d\'Or',
        'category.innovation': 'Innovation',
        'category.audacity': 'Audacity',
        'category.horlogical_revelation': 'Horological Revelation',
        'category.chronometry': 'Chronometry',

    },
    fr: {
        'visit_gphg': 'VISITEZ <a href="https://www.gphg.org/horlogerie/fr" target="_blank">WWW.GPHG.ORG</a>',
        'days': 'Jours',
        'hours': 'Heures',
        'mins': 'Mins',
        'secs': 'Secs',
        'fr': 'Français',
        'en': 'English',
        'make_sure_you_enable': 'Pensez à activer',
        'sound_by_clicking_here': 'le son en cliquant ici!',
        'copyright': '© FONDATION DU GRAND PRIX D\'HORLOGERIE DE GENÈVE. TOUS DROITS RÉSERVÉS.',
        'follow_us': 'Suivez également l\'événement sur nos réseaux sociaux',
        'event_title': 'EDITION 2024',
        'event_subtitle': 'GARDE-TEMPS PRESELECTIONNÉS PAR CATÉGORIES',

        'category.ladies': 'Femme',
        'category.ladies.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_FEMME',
        'category.ladies_complications': 'Complication pour Femme',
        'category.ladies_complications.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_COMPLICATION_POUR_FEMME',
        'category.time_only': 'Time Only',
        'category.time_only.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_TIME_ONLY',
        'category.mens': 'Homme',
        'category.mens.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_HOMME',
        'category.mens_complications': 'Complication pour Homme',
        'category.mens_complications.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_COMPLICATION_POUR_HOMME',
        'category.iconic': 'Iconique',
        'category.iconic.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_ICONIQUE',
        'category.tourbillon': 'Tourbillon',
        'category.tourbillon.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_T2024_PRE_TOURBILLON',
        'category.calendar_astronomy': 'Calendrier et Astronomie',
        'category.calendar_astronomy.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_CALENDRIER_ET_ASTRONOMIE',
        'category.mechanical_exception': 'Exception Mécanique',
        'category.mechanical_exception.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_EXCEPTION_MÉCANIQUE',
        'category.chronograph': 'Chronographe',
        'category.chronograph.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_CHRONOGRAPHE',
        'category.divers': 'Sport',
        'category.divers.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_SPORT',
        'category.jewellery': 'Joaillerie',
        'category.jewellery.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_JOAILLERIE',
        'category.artistic_crafts': 'Métiers d\'Art',
        'category.artistic_crafts.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_METIERS_D’ART',
        'category.petite_aiguille': 'Petite Aiguille',
        'category.petite_aiguille.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_PETITE_AIGUILLE',
        'category.challenge': 'Challenge',
        'category.challenge.link': 'https://www.gphg.org/horlogerie/fr/gphg-2024/preselection#2024_PRE_CHALLENGE',

        'category.aiguille_dor': '"Aiguille d\'Or"',
        'category.innovation': 'Innovation',
        'category.audacity': 'Audacity',
        'category.horlogical_revelation': 'Horological Revelation',
        'category.chronometry': 'Chronometry',
    }
}
