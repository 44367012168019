import io from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid';
import {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {useVirtual} from "react-virtual";
import FloodProtection from 'flood-protection';
import axios from 'axios';

import config from "../config"

const useChat = (socketIOWebsocket, userToken, displayName, eventsCallbacks = {}, historyUrl, shouldStickScroll, isScrolling) => {
    const bottomRef = useRef(null);
    const socketRef = useRef(null)
    const roomRef = useRef(null);
    const [messages, setMessages] = useState([])
    const [isFlooding, setIsFlooding] = useState(false);
    const [fetchingMessages, setFetchingMessages] = useState(true);
    const [timeLeft, setTimeLeft] = useState(0);
    const messageListRef = useRef();
    const allowedEventTypes = ['danger', 'info', 'message', 'reaction'];
    const floodProtection = new FloodProtection({
        rate: 5,
        // default: 5, unit: messages
        // IMPORTANT: rate must be >= 1 (greater than or equal to 1)
        per: 15,
        // default: 8, unit: seconds
    });

    const rowVirtualizer = useVirtual({
        size: messages.length,
        parentRef: messageListRef,
        paddingStart: 10,
        paddingEnd: 10,
    });

    useEffect(() => {
        if (rowVirtualizer.virtualItems.length > 0 && shouldStickScroll.current && !isScrolling) {
            if (messages.length > 0) {
                setTimeout(function () {
                    rowVirtualizer.scrollToIndex(100000, 'end')
                },200)
            }
        }
    }, [messages, rowVirtualizer.virtualItems]);

    useEffect(() => {
        if (!timeLeft) {
            setIsFlooding(false)
            return;
        }
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 100);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    /**
     * Call init chat once
     */
    useEffect(() => {
        initChat()
    }, []);

    /**
     * Close the socket when the component is destroyed
     */
    useEffect(() => {
        return () => {
            // console.log('destroying chat')
            try {
                socketRef && socketRef.current && socketRef.current.removeAllListeners();
                socketRef && socketRef.current && socketRef.current.close();
            } catch(e){
                console.log(e)
            }
        };
    }, [socketRef]);

    /**
     * Initialize the chat and bind methods
     */
    const initChat = () => {
        if (socketRef.current) {
            socketRef.current.disconnect();
        }
        let ioSocket = io(socketIOWebsocket, {
            transports: ["websocket"],
            auth: {
                token: userToken
            },
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: config.RECONNECT_DELAY,
        });

        ioSocket.on("disconnect", () => {
            addMessage({
                event_type: "info",
                data: "Disconnected",
                uuid: uuidv4(),
            })
        })

        ioSocket.on("kick", (event) => {
            if (event.display_name === displayName) {
                addMessage({
                    event_type: "info",
                    data: "You've been banned form this event",
                    uuid: uuidv4(),
                })
            
                ioSocket.close()
            }
        })

        ioSocket.on("connect", () => {
            addMessage({
                event_type: "info",
                data: "Connected",
                uuid: uuidv4(),
            })

            if (socketRef.current && roomRef) {
                socketRef.current.emit("join", roomRef.current)
            }
        })

        ioSocket.on("message", (event) => {
            addMessage(event)
        })

        ioSocket.on("delete_message", (payload) => {
            setMessages(oldMessages => oldMessages.filter((item) => {
                return item.uuid !== payload
            }));
        })

        ioSocket.on("redact", (payload) => {
            setMessages(oldMessages => oldMessages.map((item) => {
                if (payload.target === item.uuid) {
                    return {...item, data: payload.data}
                } else {
                    return item
                }
            }));
        })

        /**
         * Provides a ways to hook custom events
         */
        Object.keys(eventsCallbacks).forEach((eventType) => {
            ioSocket.on(eventType, (event) => {
                eventsCallbacks[eventType](event)
            })
        })

        socketRef.current = ioSocket
    }


    /**
     * Leave room method
     */
    const leaveRoom = () => {
        if (socketRef.current && roomRef.current) {
            socketRef.current.emit("leave", roomRef.current)
        }
    }

    /**
     * Empty list of messages
     */
    const clearMessages = () => {
        setMessages([]);
    }

    /**
     * Sends a message
     * @param message
     */
    const sendMessage = (message) => {
        if (socketRef.current) {
            if (floodProtection.check() && !isFlooding) {
                // forward message
                socketRef.current.emit("message", message)
            } else if (!floodProtection.check() && isFlooding) {
                addMessage({
                    event_type: "danger",
                    data: `${displayName} ${translations.chat_flood_warning} ${timeLeft} ${translations.chat_flood_seconds}`,
                    uuid: uuidv4(),
                })
                if (rowVirtualizer.virtualItems.length > 0) {
                    setTimeout(function () {
                        rowVirtualizer.scrollToIndex(10000)
                    },200)
                }

            } else if (!isFlooding && !floodProtection.check()) {
                // forbid message
                setTimeLeft(banTime)
                setIsFlooding(true)
                // Optionally we can emit a flood message.
                // socketRef.current.emit("flood", {
                //     text: 'Take it easy!',
                // });
            }
        }
    }

    /**
     * Sends a reaction
     * @param emoji
     */
    const sendReaction = (emoji) => {
        if (socketRef.current) {
            socketRef.current.emit("reaction", {
                room: roomRef.current,
                reaction: emoji
            })
        }
    }

    /**
     * Join room method
     * @param newRoom
     */
    const joinRoom = (newRoom) => {
        roomRef.current = newRoom
        clearMessages()
        if (historyUrl) {
            fetchOldMessages(newRoom)
        }
        
        if (socketRef.current) {
            socketRef.current.emit("join", newRoom)
            addMessage({
                event_type: "info",
                data: `Welcome ${displayName}!`,
                uuid: uuidv4(),
            })
        }
    }

    /**
     * Change room method
     * @param newRoom
     */
    const changeRoom = (newRoom) => {
        if (socketRef.current && roomRef) {
            socketRef.current.emit("leave", roomRef.current)
        }

        roomRef.current = newRoom
        clearMessages()

        if (historyUrl) {
            fetchOldMessages(newRoom)
        }

        if (socketRef.current) {
            socketRef.current.emit("join", newRoom)
            addMessage({
                event_type: "info",
                data: "Welcome",
                uuid: uuidv4(),
            })
        }
    }

    const fetchOldMessages = (room) => {
        setFetchingMessages(true)
        axios.get(historyUrl, {
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            params: {
                jwt: userToken,
                room: room,
            }
        }).then((data) => {
            setFetchingMessages(false)
            if (data.status === 200) {
                let messages = data.data.data.map(x => x = JSON.parse(x.data));
                messages = messages.filter(x => allowedEventTypes.indexOf(x.event_type) >= 0);
                return messages
            } else {
                throw data.statusText
            }
        }).then((newMessages) => {
            setFetchingMessages(false)
            setMessages(oldMessages => [...newMessages, ...oldMessages])
            //rowVirtualizer.scrollToIndex(messages.length, "auto")
        }).catch((error) => {
            setFetchingMessages(false)
            console.log(error)
        });
    }

    /**
     * Delete message method
     * @param messageToDelete
     */
    const deleteMessage = (messageToDelete) => {
        setMessages(oldMessages => oldMessages.filter((item) => {
            return item.uuid !== messageToDelete
        }));
    }

    /**
     * Add message method
     * @param message
     */
    const addMessage = (message) => {
        /**
         * Display real messages longer than system messages
         */
        const message_duration = message.event_type === "message" ? config.MESSAGE_DURATION : config.MESSAGE_SYSTEM_DURATION;

        setMessages(oldMessages => {
            const messageAlreadyIn = oldMessages.filter((item) => {
                return item.uuid === message.uuid
            })

            if (!messageAlreadyIn.length) {
                return [...oldMessages, message];
            } else {
                console.error("Found a message with the same UUID", messageAlreadyIn, message)
                return oldMessages
            }
        })
        if (message.event_type !== "message") {
            setTimeout(() => {
                deleteMessage(message.uuid)
            }, message_duration)
        }
    }

    return {
        changeRoom,
        joinRoom,
        leaveRoom,
        addMessage,
        clearMessages,
        sendMessage,
        sendReaction,
        //shouldScrollBottomRef,
        //bottomRef,
        messages,
        roomRef,
        fetchingMessages,
        rowVirtualizer,
        messageListRef
    }
}

export default useChat;
