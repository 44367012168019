import React, {useEffect, useRef, useState} from "react";
import config from "../../config";
import {translations} from "../../assets/translations";

const LanguageSwitcher = (props) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [menuOpenClass, setMenuOpenClass] = useState(false)
    const [menuWidth, setMenuWidth] = useState(40)
    let closelanguageMenuTimeout = useRef(null);

    const handleSelectLanguage = (selectedLanguage) => {
        props.setSelectedLanguage(selectedLanguage)
        toggleMenu()
    }

    const getLanguageName = (language) => {
        if (props.showFullLanguage) {
            return translations[props.selectedLanguage][language.toLowerCase()]
        }
        return language.toUpperCase()
    }

    useEffect(() => {
        return () => {
            clearTimeout(closelanguageMenuTimeout.current)
        }
    }, [])

    const toggleMenu = () => {
        if (!menuOpen) {
            setMenuWidth(props.languages.length * 40)
            setMenuOpenClass(true)
            setMenuOpen(true)
        } else {
            setMenuWidth(40)
            setMenuOpen(false)
            setMenuOpenClass(false)
        }
    }

    const closeMenu = () => {

    }

    const startAutoCloseMenu = () => {
        if (menuOpen) {
            closelanguageMenuTimeout.current = setTimeout( () => {
                toggleMenu()
            }, config.MOUSE_LEAVE_DELAY_AUTO_CLOSE_UI_ELEMENTS + 500000)
        }

    }

    const clearAutoCloseMenu = () => {
        clearTimeout(closelanguageMenuTimeout.current)
    }

    return (
        <div className={"btn-control__wrapper language" + (menuOpenClass ? " open" : "")} style={{width: menuWidth}} onMouseLeave={startAutoCloseMenu} onMouseEnter={clearAutoCloseMenu}>
            <div className="btn-control__bg__outer" style={{width: menuWidth}}>
            </div>
            {menuOpen && <ul>
                {props.languages.map((language, index) =>
                    <li key={index} className="" onClick={() => handleSelectLanguage(language)}>
                        <button className={"btn-ui-icon btn-control-lang " + (props.selectedLanguage === language ? 'active' : '')}>
                            {getLanguageName(language)}
                        </button>
                    </li>
                )}
            </ul>}

            {<button onClick={toggleMenu} className="btn-ui-icon btn-control-lang">
                {(props.showFullLanguage || !menuOpen) && getLanguageName(props.selectedLanguage)}
                {props.showFullLanguage && <span className={'mdi mdi-chevron-down'}></span>}
            </button>}
        </div>
    );
};

export default LanguageSwitcher;
