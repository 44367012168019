import React from "react";
import { toArray } from "react-emoji-render";

export function CustomEmoji({ text, className }) {
    const emojiUnicode = emoji => {
        var comp;
        if (emoji.length === 1) {
            comp = emoji.charCodeAt(0);
        }
        comp = (
            (emoji.charCodeAt(0) - 0xD800) * 0x400
            + (emoji.charCodeAt(1) - 0xDC00) + 0x10000
        );
        if (comp < 0) {
            comp = emoji.charCodeAt(0);
        }
        return comp.toString("16");
    };

    const parseEmojis = value => {
        const emojisArray = toArray(value);
        const newValue = emojisArray.reduce((previous, current) => {
            if (typeof current === "string") {
                return previous + current;
            }
            return previous + current.props.children;
        }, "");
        return emojiUnicode(newValue);
    };

    return (
        <span className={className}>
            <img width="18" height="18" src={`https://twemoji.maxcdn.com/v/latest/72x72/${parseEmojis(text)}.png`} />
        </span>
    );
}

export default CustomEmoji;
