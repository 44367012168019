import React, {useEffect, useState} from "react";
import config from "../../config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import {Tools} from '@ideative/network-diagnosis-lib';

import {
    osName,
    osVersion
} from "react-device-detect";

const QuestionsOpener = (props) => {
    return (
        <div className="openquestions-wrapper" onClick={props.onClick}>
            <button className={'btn btn-link'}>Ask a live question</button>
        </div>
    )
}

const Loading = (props) => {
    return <div className="openquestions-wrapper p-3">
        <p><FontAwesomeIcon icon={faSpinner} spin /> </p>
        <p>Please wait a few seconds while we check your network configuration...</p>
    </div>
}

const PendingStatus = (props) => {
    return <div className="openquestions-wrapper p-3 d-block" >
        <p>
            You have been added to the waiting room.
            <br />
            <small>This could take several minutes...</small>
        </p>

        <button className={'btn btn-danger mt-3'} onClick={props.onCancel}>Cancel question</button>
    </div>
}

const CancelledStatus = (props) => {
    return <div className={"openquestions-wrapper p-30 d-block"}>
        <p>We apologize you haven't had the chance to talk live during this session, please use the chat box or email<br />
            <a href={'mailto:ideative@ideative.ch'}>ideative@ideative.ch</a>
            <br />for a follow-up.</p>

        <button className={'btn close mt-3'} onClick={props.onClose}>
            <FontAwesomeIcon icon={faTimesCircle} />
        </button>

    </div>
}

const InvitedStatus = (props) => {
    return <div className="openquestions-wrapper p-3 d-block" >
        <div className={'d-block text-center mb-4'}>
            <a href={props.inviteUrl + '&Name=' + props.displayName} className={'btn btn-default mt-3 pl-50 pr-50'} onClick={props.onJoin}>
                Click here to join
            </a>
        </div>

        <div className={'circle-timer'}>
            <CountdownCircleTimer
                isPlaying
                duration={config.LIVE_QUESTION_JOIN_TIMEOUT}
                colors={[
                    ['#004165', 0.33],
                ]}
                onComplete={props.onCountdownComplete}
            >
                {({ remainingTime }) => (
                    <div className={'timer'}>
                        <div className={'value'}>
                            {remainingTime}
                        </div>
                        <div className={'text'}>seconds left</div>
                    </div>
                )}
            </CountdownCircleTimer>
        </div>


        <button className={'btn btn-danger mt-3 pl-50 pr-50 mt-4'} onClick={props.onCancel}>Cancel question</button>
    </div>
}

const JoinedStatus = (props) => {
    return (
        <div className="openquestions-wrapper p-30">
            <p>You have now joined the conference to ask your question.</p>

            <button className={'btn close mt-3'} onClick={props.onClose}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </button>
        </div>
    )
}

const ErrorStatus = (props) => {
    return (
        <div className="openquestions-wrapper p-30">
            {!props.errorMessage &&
            <p>
                An error occured. You may have been disconnected from the internet.
                <br />
                Please try again in a few seconds.
            </p>
            }
            {props.errorMessage}

            <button className={'btn close mt-3'} onClick={props.onClose}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </button>
        </div>
    )
}

const InviteTimeoutStatus = (props) => {
    return (
        <div className="openquestions-wrapper p-30">
            <p>
                Your Q&A invitation has expired.<br />
                Please try again.
            </p>

            <button className={'btn close mt-3'} onClick={props.onClose}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </button>
        </div>
    )
}

export const STATUS_NONE = 'NONE';
export const STATUS_PENDING = 'PENDING';
export const STATUS_INVITED = 'INVITED';
export const STATUS_CANCELLED = 'CANCELLED';
export const STATUS_JOINED = 'JOINED';
export const STATUS_ERROR = 'ERROR';
export const STATUS_INVITE_TIMEOUT = 'INVITE_TIMEOUT';


let heartbeatTimer = null;

const LiveQuestion = (props) => {
    const [status, setStatus] = useState(STATUS_NONE)
    const [loading, setLoading] = useState(false)
    const [inviteUrl, setInviteUrl] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const heartbeatStatuses = [STATUS_PENDING, STATUS_INVITED];

    /**
     * Executed only when the component is mounted
     * Get the initial state of the subscription based on the value passed in DOM
     */
    useEffect(() => {
        if (props.subscriptionStatus) {
            setStatus(props.subscriptionStatus.status)
        }
        if (props.subscriptionStatus && isHeartbeatStatus(props.subscriptionStatus.status)) {
            heartbeat(props.subscriptionStatus.status)
        }
    }, [])

    /**
     * Subscribe the user to the waiting list
     */
    const subscribe = () => {
        // @TODO : Replace by real values
        setLoading(true)
        Tools.isNetworkOk(
            props.networkDiagnostics.allowedBrowsers,
            props.networkDiagnostics.latencyUrl,
            props.networkDiagnostics.port,
            props.networkDiagnostics.speedUrl + '?t=' + new Date().getTime(),
            props.networkDiagnostics.maxLatency,
        ).then(networkStatus => {
            postSubscription(networkStatus)
        })
        .catch(error => {
            // If an error occured while fetching network conditions, still try to subscribe the user
            postSubscription(null)
            setLoading(false)
        })
    }

    const postSubscription = (networkStatus) => {
        axios.post('/api/live-question', {
            network_status: {
                ...networkStatus,
                os: osName + ' ' + osVersion
            }
        })
            .then (response => {
                changeStatus(STATUS_PENDING)
                setLoading(false)
            })
            .catch(error => {
                console.error(error)
                changeStatus(STATUS_ERROR)
                setLoading(false)
            })
    }

    /**
     * Cancel the user's subscription to the waiting list
     */
    const changeStatus = (toStatus = STATUS_NONE) => {
        console.log('Moving to status ' + toStatus + ' from ' + status)

        clearTimeout(heartbeatTimer)
        setStatus(toStatus)
        setInviteUrl(null)

        if (isHeartbeatStatus(toStatus)) {
            heartbeat(toStatus)
        }

        if (toStatus === STATUS_NONE) {
            props.setIsInterfaceActive(false)
        }
        else {
            // Make sure the interface stays active when we have a specific status
            props.setIsInterfaceActive(true)
        }

        const cancelStatuses = [STATUS_CANCELLED, STATUS_INVITE_TIMEOUT, STATUS_NONE]
        if (cancelStatuses.indexOf(toStatus) !== -1) {
            axios.post('/api/live-question/cancel')
        }

    }

    const isHeartbeatStatus = (currentStatus) => {
        return heartbeatStatuses.indexOf(currentStatus) !== -1
    }

    /**
     * Send a heartbeat to the server acknowledging our presence
     * We get our current status in the queue in response
     */
    const heartbeat = (currentStatus) => {
        console.log('Launching heartbeat from status ' + currentStatus)
        axios.post('/api/live-question/heartbeat', {
            params: {
                t: new Date().getTime()
            }
        })
            .then(response => {
                if (response.data && response.data.success) {
                    setStatus(response.data.status)
                    setInviteUrl(response.data.invite_url)
                    console.log('Heartbeat ACK received : ' + response.data.status)

                    if (isHeartbeatStatus(response.data.status)) {
                        heartbeatTimer = setTimeout(heartbeat, config.LIVE_QUESTION_HEARTBEAT_RATE)
                    }
                }
                else {
                    console.log('Received cancel information from heartbeat')
                    console.log("Current status : " + status)
                    changeStatus(STATUS_CANCELLED)
                }
            })
            .catch(error => {
                console.error(error)
                // If there is an error with the heartbeat, cancel our subscription
                changeStatus(STATUS_ERROR)
            })
    }

    const join = () => {
        axios.post('/api/live-question/join', {
            params: {
                t: new Date().getTime()
            }
        }).then(response => {
            if (response.data.success) {
                setStatus(response.data.status)
                if (props.onJoin) {
                    props.onJoin()
                }
            }
            else {
                changeStatus(STATUS_NONE)
            }
        })
    }

    const onClose = () => {
        setStatus(STATUS_NONE)
    }

    return (
        <>
            {loading && <Loading />}
            {!loading && status === STATUS_NONE && <QuestionsOpener onClick={subscribe} />}
            {!loading && status === STATUS_PENDING && <PendingStatus onCancel={() => changeStatus(STATUS_NONE)} />}
            {!loading && status === STATUS_INVITED && <InvitedStatus onCancel={() => changeStatus(STATUS_NONE)}
                                                                     displayName={props.displayName}
                                                                     onCountdownComplete={() => changeStatus(STATUS_INVITE_TIMEOUT)}
                                                                     onJoin={join}
                                                                     inviteUrl={inviteUrl} />}
            {!loading && status === STATUS_CANCELLED && <CancelledStatus onClose={onClose} />}
            {!loading && status === STATUS_JOINED && <JoinedStatus onClose={onClose} />}
            {!loading && status === STATUS_ERROR && <ErrorStatus onClose={onClose} errorMessage={errorMessage} />}
            {!loading && status === STATUS_INVITE_TIMEOUT && <InviteTimeoutStatus onClose={onClose} />}
        </>
    )
}
export default LiveQuestion
