import React, {useEffect, useState, useRef} from "react";
import Player from '@vimeo/player';
const VimeoPlayer = (props) => {
    let [internalPlayerScript, setInternalPlayerScript] = useState(props.playerScript)
    const playerRef = useRef(null)

    useEffect(() => {
        if (props.playerScript !== internalPlayerScript) {
            console.log("Updated player script")
            setInternalPlayerScript(props.playerScript)
        }
    }, [props.playerScript])

    useEffect(() => {
        console.log("OUI")
        if (playerRef.current && props.playerOnlyFullScreen && props.playerOnly) {
            playerRef.current.requestFullscreen()
        }
    }, [props.playerOnlyFullScreen])

    useEffect(() => {
        const iframe = document.querySelector('#vimeo-player');
        if (iframe) {
            playerRef.current = new Player(iframe);
            playerRef.current.setVolume(props.volume ? 1 : 0);
        }
    }, [props.volume])

    return (
        <div className={'vimeo-player-container'}>
            <iframe id={'vimeo-player'} src={props.playerScript + '?autoplay=1&title=0&byline=0&portrait=0&controls=' + (props.event.status === 'replay' ? 1 : 0)} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
        </div>
    )
};
export default VimeoPlayer;
