import React, {useState} from "react";
import { Twemoji } from 'react-emoji-render';


import config from "../../config"
import CustomEmoji from "../reactions/CustomEmoji";

const ReactionPicker = (props) => {
    const [reactionCooldown, setReactionCooldown] = useState(false)

    const handleSendReaction = (emoji) => {
        /**
         * Only send a reaction if there is no cooldown running
         */
        // if (!reactionCooldown) {
            props.sendReaction({
                "event_type": "reaction",
                "data": emoji,
                "room": props.roomName,
            })

            /**
             * Activate the cooldown to prevent the user from sending too much reactions
             */
            // setReactionCooldown(true)
            handleOpenMenu()
            // setTimeout(() => {
            //     setReactionCooldown(false)
            // }, config.REACTION_COOLDOWN_DURATION)
        // }
    }

    const handleOpenMenu = () => {
        props.setDisplayFullPicker(oldValue => !oldValue)
        props.setHideMessageInput(oldValue => !oldValue)
    }

    return (
        <>
            {props.displayFullPicker && <ul>
                {config.REACTION_ALLOWED_EMOJIS.map((emoji, index) =>
                    <li key={index} className="list-inline-item cursor-pointer" onClick={() => handleSendReaction(emoji)}>
                        {/*<Emoji className={`emojy-icon ${reactionCooldown ? "" : "hoverable"}`} text={emoji} onlyEmojiClassName="emoji" />*/}
                        <CustomEmoji className="emojy-icon hoverable" text={emoji} onlyEmojiClassName="emoji" />
                    </li>
                )}
            </ul>}

            <button className={"btn-ui-icon btn-ui-open-smileys icon mr-10 " + (!props.displayFullPicker ? "" : "d-none")} onClick={handleOpenMenu}>
                <span className={props.displayFullPicker ? "d-none" : "mdi mdi-emoticon-outline"}></span>
                <span className={props.displayFullPicker ? "" : "d-none"}>x</span>
            </button>
        </>
    )
}

export default ReactionPicker;
