import React, {useState, useEffect} from "react";

import Messages from "../components/chat/Messages";
import Input from "../components/chat/Input";
import ReactionPicker from "../components/chat/ReactionPicker";

const Chat = (props) => {
    const [hideMessageInput, setHideMessageInput] = useState(false)
    const [hideMessages, setHideMessages] = useState(false)
    const [displayFullPicker, setDisplayFullPicker] = useState(false)

    const handleSwitchChat = (hide) => {
        setHideMessages(hide);
        if (!hide){
            setHideMessageInput(false);
            setDisplayFullPicker(false);
            window.dispatchEvent(new Event('scroll'));
            props.rowVirtualizer.scrollToIndex(100000, 'end');
        }
    };
    useEffect(()=> {handleSwitchChat(true)}, []);

    return (
        <>
            <div id="chat" className={"chat-controls text-white " + (props.messages.length > 0 ? "has-messages" : "empty")}>
                {!hideMessages && <Messages
                    messageListRef={props.messageListRef}
                    rowVirtualizer={props.rowVirtualizer}
                    messages={props.messages}
                    history={props.history}
                    fetchingMessages={props.fetchingMessages}
                />}


                <div className="row">
                    <div className="col d-flex justify-content-end position-relative mt-10">
                        <div className="msg-reactions-wrapper">
                            <ReactionPicker
                                displayFullPicker={displayFullPicker}
                                setDisplayFullPicker={setDisplayFullPicker}
                                sendReaction={props.sendReaction}
                                setHideMessageInput={setHideMessageInput}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Chat;
