import React, {useEffect, useState} from "react";
import moment from "moment";
import "moment-timezone"
import {translations} from "../../assets/translations";

const Countdown = (props) => {
    // moment tz of passed start_date
    const start_date = moment.tz(props.startDate, "Europe/Zurich");
    // moment duration of difference between start_date and now
    const diffDuration = moment.duration(start_date.diff(moment.tz("Europe/Zurich")));
    const [diffDate, setDiffDate] = useState(diffDuration._milliseconds > 0 ? diffDuration : null)
    const [countdownActive, setCountdownActive] = useState(diffDuration._milliseconds > 0)

    let countDownAF = null

    useEffect(() => {
        // manage body class
        document.querySelector('body').classList.add('before-after-page')
        // launch countdown
        countDownAF = requestAnimationFrame(execCountDown)
        return () => {
            document.querySelector('body').classList.remove('before-after-page')
            cancelAnimationFrame(countDownAF)
        }
    }, [])

    /**
     * Loop for the countdown
     */
    const execCountDown = () => {
        const diffDuration = moment.duration(start_date.diff(moment.tz("Europe/Zurich")));
        if (diffDuration._milliseconds > 0) {
            setDiffDate(diffDuration)
            countDownAF = requestAnimationFrame(execCountDown)
        } else {
            setCountdownActive(false)
        }
    }
    /**
     *
     * @param num
     * @returns {string|*}
     */
    const myFormat = (num) => {
        return num < 10 ? "0" + num : num;
    }

    if (countdownActive) {
        return (
            <>
            <div className="content-wrapper">
                <div className={'d-flex justify-content-center'}>
                    <div className="countdown-item">
                        <div className="inner">
                            <span className="d-block days countdown-value">{ myFormat(parseInt(diffDate.asDays()))}</span>
                            <label>{translations[props.selectedLanguage].days}</label>
                        </div>
                    </div>
                    <div className="countdown-item">
                        <div className="inner">
                            <span className="d-block hours countdown-value">{ myFormat(diffDate.hours()) }</span>
                            <label>{translations[props.selectedLanguage].hours}</label>
                        </div>
                    </div>
                    <div className="countdown-item">
                        <div className="inner">
                            <span className="d-block minutes countdown-value">{ myFormat(diffDate.minutes()) }</span>
                            <label>{translations[props.selectedLanguage].mins}</label>
                        </div>
                    </div>
                    <div className="countdown-item">
                        <div className="inner">
                            <span className="d-block seconds countdown-value">{ myFormat(diffDate.seconds()) }</span>
                            <label>{translations[props.selectedLanguage].secs}</label>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    } else {
        return (<></>);
    }
}
export default Countdown
