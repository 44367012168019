import React, {useEffect, useState, useReducer} from "react";
import config from "../../config";

const Watermark = (props) => {
    let watermarkRealSize = 0

    // let diagonale = 0
    const [diagonale, setDiagonale] = useState(0)

    useEffect(() => {
        getDesiredFontSize()
        window.addEventListener('resize', getDesiredFontSize)

        return (() => {
                window.removeEventListener('resize', getDesiredFontSize);
            }
        )
    }, [])


    /**
     * calc the desired font size to fit diagonale of container on one line
     */
    const getDesiredFontSize = () => {
        const source = document.querySelector('.watermark')
        // if we have no "real" text width ref, calculate & set it once for all
        if (watermarkRealSize === 0) {
            // clone & inject the text in abs position to get its dimensions on one line with font-size 100 (css)
            const clone = source.cloneNode(true);
            clone.classList.add('watermark__test')
            source.after(clone)
            // calc "real" text size at fz 100px,
            watermarkRealSize = clone.offsetWidth
            // remove test clone
            clone.remove();
        }
        setDiagonale(oldDiagonale => {
            // get diagonale size of container, with a safety margin
            const newDiagonale = Math.hypot(source.parentElement.offsetWidth, source.parentElement.offsetHeight) - config.WATERMARK_DIAGONALE_SAFETY_MARGIN
            if (watermarkRealSize > 0) {
                // get new font size, maxed by config setting
                const newFontSize = Math.min(100 / watermarkRealSize * newDiagonale, config.WATERMARK_MAX_FONT_SIZE);
                // add fontSize to source
                source.style.fontSize = newFontSize + "px";
                source.classList.add('resized');
            }
            return newDiagonale
        });
    }

    return (
        <div className="watermark pointer-events-none">
            {props.displayName}
        </div>
        // <div className="watermark">ideative@ideative.ch</div>
    )
}

export default Watermark
