import React from "react";

import config from "../../config"

const regularMsgAnimationDelay = config.MESSAGE_DURATION - config.MESSAGE_FADEOUT_DURATION
const systemMsgAnimationDelay = config.MESSAGE_SYSTEM_DURATION - config.MESSAGE_FADEOUT_DURATION
const RegularMessage = (props) => {
    return (
        <div className={`message mt-2  ${props.history ? "" : "disappear "}` + props.message.uuid } style={{opacity: 1, animationDelay: regularMsgAnimationDelay+'ms', animationDuration:config.MESSAGE_FADEOUT_DURATION+'ms'}}>
            <span className="font-weight-bold">{props.message.user}</span>{/* <span
            className="small">{moment(props.message.time).format('H:mm:ss')}</span>*/} <br/>
            {props.message.data}
        </div>
    )
}

const ReplyMessage = (props) => {
    return (
        <div className={`message mt-2  ${props.history ? " " : "disappear "}` + props.message.uuid } style={{opacity: 1, animationDelay: regularMsgAnimationDelay+'ms', animationDuration:config.MESSAGE_FADEOUT_DURATION+'ms'}} >
            <span className="font-weight-bold">{props.message.user}</span>
            <div className="reply ml-5 px-2 border-l-2 my-2">
                <span className="font-bold opacity-50">{props.message.reply.to.display_name}</span><br />
                <p className="message text-black leading-normal">
                    {props.message.reply.data.split("\n").map((item, index) => {
                        return <span key={index} style={{display: "flex", width: "100%"}}>{item}<br/></span>
                    })}
                </p>
            </div>
            {/* <span
            className="small">{moment(props.message.time).format('H:mm:ss')}</span>*/}
            {props.message.data}<br/>
        </div>
    )
}

const InfoMessage = (props) => {
    return (
        <div className="message-system mt-2 small" style={{opacity: 0.5, animationDelay: systemMsgAnimationDelay+'ms', animationDuration:config.MESSAGE_FADEOUT_DURATION+'ms'}}>
            {props.message.data}
        </div>
    )
}

const Message = (props) => {
    switch (props.item.event_type) {
        case "message":
            if(props.item.reply) {
                return (<ReplyMessage history={props.history} key={props.item.uuid} message={props.item}/>)
            } else {props.item
                return (<RegularMessage history={props.history} key={props.item.uuid} message={props.item}/>)
            }
        case "info":
            return (<InfoMessage key={props.item.uuid} message={props.item}/>)
    }
}

const Messages = (props) => {
    return (
        <>
          <div
            ref={props.messageListRef} 
            className="List"
            style={{
                maxHeight: config.CHAT_WINDOW_HEIGHT + "px",
                overflow: 'auto',
            }}
          >
            <div
              className={`ListInner text-white w-100 mb-20  ${props.messages.length > 5 ? "gradient" : ""}`}
              id="messages"
              style={{
                height: `${props.rowVirtualizer.totalSize}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {props.rowVirtualizer.virtualItems.map(virtualRow => (
                <div
                  key={virtualRow.index}
                  ref={(element) => virtualRow.measureRef(element)}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: `${props.messages[virtualRow.index]}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                    <Message history={props.history} keyProps={props.messages[virtualRow.index].uuid} item={props.messages[virtualRow.index]}/>
                </div>
              ))}
            </div>
          </div>
        </>
    )
}
export default Messages;
