import React from "react";
import CustomEmoji from "./CustomEmoji";

const Reactions = (props) => {
    return (
        <div className={"animated-emojis " + (props.isMobileOnly ? "mobile-only" : "")}>
            <div className="animated-emojis__inner">
                {Object.keys(props.reactions).map((channel, index) => (
                    <div key={index} className={"animated-emojis__inner__channel"}>
                        {props.reactions[channel].map((item, key2) => (
                            <div key={key2} className="animated-emojis__inner__item" style={{fontSize: item.size + "rem"}}><CustomEmoji text={item.data} /></div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Reactions;
