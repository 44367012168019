import React, {useEffect} from "react"
import Countdown from "../countdown/Countdown";
import {translations} from "../../assets/translations";

const Before = (props) => {
    useEffect(() => {
        document.querySelector('body').classList.add('before-after-page')

        return () => document.querySelector('body').classList.remove('before-after-page')
    })
    useEffect(() => {
        if (props.event.background_before_event) {
            document.querySelector(":root").style.setProperty("--before-backgroundimage", 'url(/storage/'+props.event.background_before_event+')');
        }
    }, [])
    return (
        <div className="container-fluid">
            <div className="row">
                <div className={'before-container col-12 text-center'}>
                    <img className={'img-fluid'} src={'/images/home_transparent_' + props.selectedLanguage + '.png'} />
                    <div className="intro-text col-12 text-center" dangerouslySetInnerHTML={{__html: props.event.description_before_event[props.selectedLanguage]}}></div>
                    <div className="countdownpage">
                        <Countdown startDate={props.event.start_date} selectedLanguage={props.selectedLanguage} />
                    </div>
                </div>

                <div className="footer small px-20 py-50 w-100 px-md-50 px-lg-0 text-center text-lg-left">
                    <div className={'row'}>
                        <div className={'col-lg-6'}>
                            <span dangerouslySetInnerHTML={{__html: translations[props.selectedLanguage].copyright}}></span>
                        </div>
                        <div className={'col-lg-6 text-center text-lg-right'}>
                            <span dangerouslySetInnerHTML={{__html: translations[props.selectedLanguage].visit_gphg}}></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Before

