export default {

    /**
     * ----------------------------------------------------------------------------------------
     * Messages
     * ----------------------------------------------------------------------------------------
     */


    /**
     * Size of the message buffer
     * (older messages will be discarded)
     */
    MESSAGE_BUFFER_SIZE: 50,

    /**
     * Display duration of a message before being removed
     */
    MESSAGE_DURATION: 30000,

    /**
     * Duration of the message fadeout
     * Animation delay of message will be MESSAGE_DURATION - MESSAGE_FADEOUT_DURATION
     */
    MESSAGE_FADEOUT_DURATION:500,

    /**
     * Display duration of a system messages before being removed
     */
    MESSAGE_SYSTEM_DURATION: 5000,

    /**
     * Height of chat window
     */
    CHAT_WINDOW_HEIGHT: 300,


    /**
     * ----------------------------------------------------------------------------------------
     * Questions
     * ----------------------------------------------------------------------------------------
     */

    /**
     * Duration of the message confirming the question was sent
     */
    QUESTION_SENT_CONFIRMATION_DURATION: 5000,
    /**
     * Message to show when question is sent
     */
    QUESTION_SENT_CONFIRMATION_MESSAGE: 'Your question has been sent',


    /**
     * ----------------------------------------------------------------------------------------
     * Reactions
     * ----------------------------------------------------------------------------------------
     */

    /**
     * Emojis allowed for the reaction (order is important)
     */
    REACTION_ALLOWED_EMOJIS: [
        ":heart_eyes:",
        ":heart:",
        ":partying_face:",
        ":fire:",
        ":thumbsup:",
        ":clap:",
        ":muscle:",
    ],

    /**
     * Max inflight reactions
     */
    REACTION_INFLIGHT_LIMIT: 600,

    /**
     * Increase the sizes of the reactions randomly between the following values
     */
    REACTION_MIN_SCALE_FACTOR: 1.5,
    REACTION_MAX_SCALE_FACTOR: 1.5,


    /**
     * ----------------------------------------------------------------------------------------
     * Throttling
     * ----------------------------------------------------------------------------------------
     */

    /**
     * Message throttle configuration
     * 4000
     */
    MESSAGE_COOLDOWN_DURATION: 500,

    /**
     * Reaction throttle configuration
     */
    REACTION_COOLDOWN_DURATION: 4000,


    /**
     * ----------------------------------------------------------------------------------------
     * General
     * ----------------------------------------------------------------------------------------
     */

    /**
     * Time before trying to reconnect to SocketIO
     * (ms)
     */
    RECONNECT_DELAY: 1000,

    /**
     * Watermark diagonale safety margin
     * will be subtracted to diagonale
     */

    WATERMARK_DIAGONALE_SAFETY_MARGIN: 500,
    /**
     * Watermark maximum allowed font size
     * 550 seems ok in most situations (very short or very long text)
     */

    WATERMARK_MAX_FONT_SIZE: 550,

    /**
     * Delay before hiding interface if mouse doesn't move
     */
    MOUSE_MOVE_DELAY_HIDE_INTERFACE: 5000,

    /**
     * Delay after mouseleave volume slider AND lang menu after which auto close slider
     */
    MOUSE_LEAVE_DELAY_AUTO_CLOSE_UI_ELEMENTS: 2000,

    /**
     * How long to display the tooltip
     */
    TOOLTIP_VOLUME_DURATION: 5000,

    /**
     * Delay between 2 POST requests for people that are subscription to the live question queue
     * Posts are to /api/live-question/heartbeat
     */
    LIVE_QUESTION_HEARTBEAT_RATE: 4000,

    /**
     * Maximum time allowed to wait for someone to click on Join the conference for a live question
     */
    LIVE_QUESTION_JOIN_TIMEOUT: 15,

    /**
     * Time between each poll in the Q&A Admin page
     */
    LIVE_QUESTION_ADMIN_POLLING_INTERVAL: 3,
}
