import React, {useEffect, useRef} from "react";
import config from "../../config"


const VolumeButtonOnly = (props) => {
    /*
    useEffect(() => {
        const tooltipInterval = setTimeout(() => {
            props.setShowTooltip(false)
        }, config.TOOLTIP_VOLUME_DURATION)

        return () => {
            clearTimeout(tooltipInterval);
        }
    }, [])
     */

    const toggleVolume = () => {
        if (props.volume === 1) {
            props.setVolume(0)
        } else {
            props.setVolume(1)
        }
    }

    return (
        <>
            <div className={"btn-control__wrapper volume"}>
                <div className="btn-control__bg__outer">
                    <div className="btn-control__bg__inner" role="button" aria-label="Toggle Volume">
                    </div>
                </div>
                <button ref={props.setTriggerRef} className={"btn-control mdi " + (props.volume === 0 ? "mdi-volume-mute" : "mdi-volume-high")} onClickCapture={toggleVolume}/>
            </div>
        </>


    )
}
export default VolumeButtonOnly
