import React from "react";
import LanguageSwitcher from "../language/LanguageSwitcher";
const Header = (props) => {
    const getAvailableLanguages = () => {
        return Object.keys(props.event.stream_script).filter((row) => !!props.event.stream_script[row] && props.activeLocales.indexOf(row) >= 0)
    }

    return (
        <header>
            <div className={'container'}>
                <div className="header-row">
                    <div className="header-col left">
                    </div>
                    {/* !(props.isMobileOnly && (props.applicationStatus === 'live' || props.applicationStatus === 'coffee_break')) &&<div className="header-col text-center d-none d-lg-block">
                        <img src={logo} alt="Brand logo"/>
                    </div>*/}
                    <div className="header-col right user-info text-right d-flex">
                        <LanguageSwitcher
                            showFullLanguage={true}
                            languages={getAvailableLanguages()}
                            selectedLanguage={props.selectedLanguage}
                            setSelectedLanguage={props.setSelectedLanguage}
                        />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
